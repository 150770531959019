import { PedagogicalDiaryService } from "@/core/services/apis.service";

export const lateArrival = (payload) => {
  return PedagogicalDiaryService.post(`student-class/late-arrival`, payload);
};

export const lateArrivalWithTime = (payload) => {
  return PedagogicalDiaryService.post(`student-class/late-arrival-by-time`, payload);
};

export const checkExistingRecordInDatetimeRange = (
  date,
  studentUID
) => {
  return PedagogicalDiaryService.get(
    `/student-class/student-uuid/${studentUID}/date/${date}`
  );
};

export const studentListWithLateArrivalStatus = (
  data
) => {
  return PedagogicalDiaryService.get(
    `/lateness/students/date/${data}`
  );
};

export const historicLateArrivalRecords = (
  startDate,
  endDate
) => {
  return PedagogicalDiaryService.get(
    `/lateness/students/start-date/${startDate}/end-date/${endDate}`
  );
};

export default {
  lateArrival,
  checkExistingRecordInDatetimeRange,
  studentListWithLateArrivalStatus,
  historicLateArrivalRecords,
  lateArrivalWithTime
};
