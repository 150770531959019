<template>
  <div>
    <div class="white rounded-lg pa-5 py-md-8 px-md-10">
      <template>
        <!-- begin::Page header and title -->
        <div class="card-title">
          <h3 class="card-label">
            Control de llegadas tarde a la institución
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui podrá registrar si un estudiante llega tarde a la
              institución.</span
            >
          </h3>
        </div>
        <!-- end::Page header and title -->

        <!-- ------------------lateness arrival record-------------- -->

        <!-- Begin:: Time picker -->
        <v-container>
          <v-card outlined class="mb-4 rounded-lg py-4">
            <v-row class="pt-4 px-5 d-flex justify-space-between">
              <v-col
                cols="12"
                md="4"
                class="d-flex justify-center align-center flex-column"
              >
                <p class="text-h4 font-weight-bold text-left">
                  <span> Instrucción </span>
                </p>
                <p class="text-body-1">
                  El día se aplica en general y la hora según el estudiante que
                  se seleccione.
                </p>
              </v-col>

              <!-- ------------------------------- -->

              <v-col
                cols="12"
                md="4"
                class="d-flex justify-center align-center flex-column"
              >
                <v-dialog
                  ref="arrivalDayPickerModalState"
                  v-model="arrivalDayPickerModalState"
                  :return-value.sync="selectedLateArrivalDay"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-2"
                      v-model="selectedLateArrivalDay"
                      label="Día"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      rounded
                      filled
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :max="today"
                    v-model="selectedLateArrivalDay"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="arrivalDayPickerModalState = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.arrivalDayPickerModalState.save(
                          selectedLateArrivalDay
                        ),
                          updateStudentListByChangingDay()
                      "
                    >
                      Registrar
                    </v-btn>
                  </v-date-picker>
                </v-dialog>

                <v-btn
                  @click="setCurrentDay()"
                  elevation="0"
                  class="light-blue mt-n3 mb-4"
                  ><span class="font-weight-medium white--text"
                    >Establecer dia actual</span
                  ></v-btn
                >
              </v-col>

              <!-- ------------------------------- -->
              <v-col
                cols="12"
                md="4"
                class="d-flex justify-center align-center flex-column"
              >
                <v-dialog
                  ref="dialog"
                  v-model="arrivalTimePickerModalState"
                  :return-value.sync="selectedLateArrivalTime"
                  persistent
                  width="340px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-2"
                      v-model="selectedLateArrivalTime"
                      label="Hora de llegada"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      readonly
                      rounded
                      filled
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    ampm-in-title
                    format="24hr"
                    v-if="arrivalTimePickerModalState"
                    v-model="selectedLateArrivalTime"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="arrivalTimePickerModalState = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(selectedLateArrivalTime)"
                    >
                      Registrar
                    </v-btn>
                  </v-time-picker>
                </v-dialog>

                <v-btn
                  @click="setCurrentTime()"
                  elevation="0"
                  class="primary mt-n3 mb-4"
                  ><span class="white--text font-weight-medium"
                    >Establecer hora actual</span
                  ></v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-container>

        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              rounded
              prepend-icon=""
              prepend-inner-icon="mdi-magnify"
              placeholder="Buscar estudiante"
              v-model="students.search"
              clearable
            ></v-text-field>
          </v-col>

          <v-col cols="12" v-if="theresDeferredLateArrivalStudentsInList">
            <p class="font-weight-bold text-body-1 text-center">
              Se
              {{
                selectedLateArrivalStudentsList.length > 1
                  ? `han seleccionado ${selectedLateArrivalStudentsList.length} estudiantes.`
                  : `seleccionó solo un estudiante.`
              }}
            </p>
          </v-col>
        </v-row>

        <!--begin: Datatable-->

        <v-data-table
          v-if="searchNotEmpty"
          :headers="students.headers"
          :items="sortedStudents"
          :search="students.search"
          :items-per-page="3"
          :loading="areStudentsLoading"
          :mobile-breakpoint="800"
          no-results-text="No se encontraron estudiantes con el nombre o código de carnet ingresado en la búsqueda."
          dense
        >
          <!-- student picture -->
          <template v-slot:[`item.photo`]="{ item }">
            <template>
              <v-avatar size="70" class="my-2">
                <v-img :src="item.photo"></v-img>
              </v-avatar>
            </template>
          </template>

          <!-- Student code -->
          <template v-slot:[`item.first_name`]="{ item }">
            <template>
              <p class="mb-0 font-weight-medium text-body-1">
                {{ item.first_name }}
              </p>
            </template>
          </template>
          <template v-slot:[`item.last_name`]="{ item }">
            <template>
              <p class="mb-0 font-weight-medium text-body-1">
                {{ item.last_name }}
              </p>
            </template>
          </template>

          <!-- Student code -->
          <template v-slot:[`item.code`]="{ item }">
            <template>
              <p class="mb-0 font-weight-bold text-body-1">
                {{ item.code }}
                <v-icon
                  color="grey darken-1"
                  small
                  class="ml-1"
                  @click="copyToClipboard(item.code)"
                  >mdi-content-copy</v-icon
                >
              </p>
            </template>
          </template>

          <!-- begin:: Late arrival check record-->
          <template v-slot:[`item.actions`]="{ item }">
            <template v-if="item.lateArrival">
              <v-tooltip color="deep-orange" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    color="orange lighten-4"
                  >
                    <v-icon color="orange"> mdi-account-clock-outline </v-icon>
                  </v-btn>
                </template>
                <span>Ya se asignó una llegada tarde el {{ selectedDay }}</span>
              </v-tooltip>
            </template>
            <template v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-checkbox
                    v-bind="attrs"
                    v-on="on"
                    @click="appendTimeToSelectedStudent(item)"
                    v-model="selectedLateArrivalStudentsList"
                    :value="item"
                  >
                  </v-checkbox>
                </template>
                <span>Marcar llegada tarde</span>
              </v-tooltip>
            </template>
          </template>
        </v-data-table>

        <!-- begin::Selected students list of cards to review before save -->
        <v-row class="flex-column mx-1 my-3">
          <!-- begin:: generated list -->
          <!-- begin::fallback alert if the search bar is empty -->
          <v-col
            cols="12"
            class="mt-4"
            v-if="!theresDeferredLateArrivalStudentsInList"
          >
            <v-row>
              <v-col
                class="mx-auto d-flex flex-column"
                cols="12"
                sm="10"
                md="6"
              >
                <div
                  class="mx-auto mb-4 pa-8 rounded-circle deep-orange lighten-4"
                >
                  <v-icon large color="deep-orange"
                    >mdi-account-multiple-plus-outline</v-icon
                  >
                </div>
                <div>
                  <p class="text-center font-weight-medium text-body-1 mb-1">
                    Para pasar al siguiente paso de la solicitud, debe
                    seleccionar almenos a un estudiante.
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <!-- end::fallback alert if the search bar is empty -->

          <v-col v-else cols="12">
            <v-list>
              <v-list-item
                v-for="(
                  student, index
                ) in selectedLateArrivalStudentsWithTimeList"
                :key="index"
                class="mx-n4 px-0"
              >
                <v-list-item-avatar size="64">
                  <v-img :src="student.photo"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold"
                    >{{ student.code }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="font-weight-medium text-body-1">
                    {{ student.full_name }}
                  </v-list-item-subtitle>

                  <v-list-item-title class="font-weight-bold mt-1">
                    <v-chip small class="mr-1" color="orange lighten-5">
                      <span class="orange--text">{{ student.date }}</span>
                    </v-chip>
                    <v-chip small color="red lighten-5">
                      <span class="red--text">{{ student.arrival_time }}</span>
                    </v-chip>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    class="red lighten-4"
                    @click="appendTimeToSelectedStudent(student)"
                  >
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <div class="d-flex flex-column justify-center mt-2">
              <v-divider></v-divider>
              <v-btn
                :loading="isSavingLateArrivals"
                :disabled="isSavingLateArrivals"
                @click="registerLateArrivals()"
                class="primary mt-4 mb-n5"
                ><span class="white--text font-weight-medium"
                  >Registrar llegadas tarde</span
                ></v-btn
              >
            </div>
          </v-col>
          <!-- end:: generated list -->
        </v-row>
        <!-- End:: Time picker -->
      </template>
    </div>
  </div>
</template>

<script>
import latenessRepository from "@/repositories/latenessRepository";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import dayjs from "dayjs";
import Swal from "sweetalert2";
export default {
  name: "SchoolAttendanceControl",
  title: "Control de llegada | GE ITR",

  mounted() {
    this.getStudents(this.today);
    this.setCurrentTime();
    this.setCurrentDay();
    this.getCurrentDay();
    this.ticktock();
    setInterval(this.ticktock, 1000);

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Diario Pedagógico", route: "lateness" },
      {
        title: "Control de llegadas tarde a la institución",
        route: "lateness",
      },
    ]);
  },

  data() {
    return {
      //Time picker
      selectedLateArrivalTime: null,
      selectedLateArrivalDay: null,
      arrivalTimePickerModalState: false,
      arrivalDayPickerModalState: false,

      selectedLateArrivalStudentsList: [],
      selectedLateArrivalStudentsWithTimeList: [],

      currentTime: "",
      currentDay: "",
      log: {},

      isSavingLateArrivals: false,
      areStudentsLoading: false,

      students: {
        headers: [
          {
            text: "Foto",
            value: "photo",
            align: "center",
            sortable: false,
            filterable: false,
          },
          {
            text: "Nombre",
            value: "first_name",
            align: "center",
            filterable: true,
          },
          {
            text: "Apellido",
            value: "last_name",
            align: "center",
            filterable: true,
          },
          { text: "Código", value: "code", align: "center", filterable: true },
          { text: "Grado", value: "grade", align: "center", filterable: false },
          {
            text: "¿Llegó tarde?",
            value: "actions",
            sortable: false,
            align: "center",
            width: 25,
            filterable: false,
          },
        ],
        data: [],
        search: "",
        filters: {},
      },
    };
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    selectedDay() {
      return this.fullFormattedDate(
        this.currentDate,
        "dddd D [de] MMMM [del] YYYY"
      );
    },
    searchNotEmpty() {
      return !!this.students.search;
    },
    theresDeferredLateArrivalStudentsInList() {
      return this.selectedLateArrivalStudentsList.length > 0;
    },
    today() {
      let date = new Date();
      //includes time
      return date.toISOString();
    },
    sortedStudents() {
      return this.students.data.sort((a, b) => {
        const aIsSelected = this.selectedLateArrivalStudentsList.includes(a);
        const bIsSelected = this.selectedLateArrivalStudentsList.includes(b);

        if (aIsSelected && !bIsSelected) {
          return -1;
        }
        if (!aIsSelected && bIsSelected) {
          return 1;
        }
        return 0;
      });
    },
  },

  methods: {
    getStudents(date) {
      this.students.data = [];
      this.areStudentsLoading = true;
      latenessRepository
        .studentListWithLateArrivalStatus(date)
        .then(({ data }) => {
          this.students.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.areStudentsLoading = false;
        });
    },

    updateStudentListByChangingDay() {
      if (this.selectedLateArrivalStudentsWithTimeList.length > 0) {
        Swal.fire({
          title: "¿Cambiar día?",
          text: "Se borrará la lista de estudiante seleccionados.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, remover",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.selectedLateArrivalDay) {
              this.getStudents(this.selectedLateArrivalDay);
              this.selectedLateArrivalStudentsWithTimeList = [];
              this.selectedLateArrivalStudentsList = [];
            }
          }
        });
      } else {
        this.getStudents(this.selectedLateArrivalDay);
      }
    },

    registerLateArrivals() {
      this.isSavingLateArrivals = true;

      latenessRepository
        .lateArrivalWithTime(this.selectedLateArrivalStudentsWithTimeList)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: "Llegadas tarde registradas correctamente",
          });

          this.selectedLateArrivalStudentsWithTimeList = [];
          this.selectedLateArrivalStudentsList = [];
          this.getStudents(this.today);
          // this.setCurrentTime();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.isSavingLateArrivals = false;
        });
    },

    getCurrentDay() {
      // Set locale to Spanish
      dayjs.locale("es");

      const today = dayjs();
      this.currentDay = today.format("dddd, DD [de] MMMM [de] YYYY");
    },

    ticktock() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const seconds = now.getSeconds().toString().padStart(2, "0");

      // Format the time as HH:MM:SS
      this.currentTime = `${hours}:${minutes}:${seconds}`;
    },

    setCurrentTime() {
      this.selectedLateArrivalTime = dayjs().format("HH:mm");

      this.fireToast({
        icon: "success",
        title: "Se actualizó la hora de llegada",
      });
    },

    setCurrentDay() {
      if (this.selectedLateArrivalStudentsWithTimeList.length > 0) {
        Swal.fire({
          title: "¿Cambiar día?",
          text: "Se borrará la lista de estudiante seleccionados.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, remover",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.selectedLateArrivalDay) {
              this.selectedLateArrivalDay = dayjs().format("YYYY-MM-DD");

              this.fireToast({
                icon: "success",
                title: "Se regresó al día actual",
              });
              this.selectedLateArrivalStudentsWithTimeList = [];
              this.selectedLateArrivalStudentsList = [];
              this.getStudents(this.today);
            }
          }
        });
      } else {
        this.selectedLateArrivalDay = dayjs().format("YYYY-MM-DD");

        this.fireToast({
          icon: "success",
          title: "Se regresó al día actual",
        });
      }
    },

    appendTimeToSelectedStudent(selectedStudent) {
      // Find the index in array1
      const indexInArray1 =
        this.selectedLateArrivalStudentsWithTimeList.findIndex(
          (item) => item.code === selectedStudent.code
        );

      // Find the index in array2
      const indexInArray2 = this.selectedLateArrivalStudentsList.findIndex(
        (item) => item.code === selectedStudent.code
      );

      if (indexInArray1 !== -1 && indexInArray2 !== -1) {
        // Student already exists, remove it
        Swal.fire({
          title: "¿Remover registro de llegada tarde?",
          text: "Tendrá que volver a buscar el estudiante si desea añadirlo a la lista otra vez.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, remover",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            this.selectedLateArrivalStudentsWithTimeList.splice(
              indexInArray1,
              1
            );
            this.selectedLateArrivalStudentsList.splice(indexInArray2, 1);

            this.fireToast({
              icon: "success",
              title: "Se removió al estudiante de la lista",
            });
          }
        });
      } else {
        // Student does not exist, add it
        let student = {
          arrival_time: this.selectedLateArrivalTime,
          date: this.selectedLateArrivalDay,
          student_id: selectedStudent.uuid,
          full_name: selectedStudent.full_name,
          code: selectedStudent.code,
          photo: selectedStudent.photo,
          user_id: this.currentUserPersonalInfo.id_user,
        };

        this.selectedLateArrivalStudentsWithTimeList.push({ ...student });
      }

      // Reset search
      this.students.search = null;
    },
  },
};
</script>
